/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

import CopyPermissionsModal from './copy-permissions-modal';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import {CompareImmutable, GetAttr} from '~/src/common/utils/utils';
import {PermissionPackageActions, PermissionPackageConstants} from '~/src/security/permission-package/permission-package-actions';
import PermissionPackageStore from '~/src/security/permission-package/permission-package-store';
import {UserActions} from '~/src/user/user-actions';

/**
 * Add preset permissions packages section.
 */
let presetsUserProps = ['requestAdditionalSecurity'];

class Presets extends Component {
    static get propTypes() {
        return {
            canCopyPermissions: PropTypes.bool,
            disabled: PropTypes.bool,
            entity: PropTypes.string,
            user: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canCopyPermissions: false,
            disabled: true,
            entity: ''
        };
    }

    static getStores() {
        return [PermissionPackageStore];
    }

    static calculateState() {
        return {
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedPermissionPackage: undefined,
            showCopyPermissionsModal: false,
        }, this.constructor.calculateState());

        this.handleSelectPermissionPackage = this.handleSelectPermissionPackage.bind(this);
        this.toggleCopyPermissionsModal = this.toggleCopyPermissionsModal.bind(this);
    }

    componentWillMount() {
        this.storeListeners = [
            PermissionPackageStore.addListener(() => {
                this.setState({
                    permissionPackages: PermissionPackageStore.getState().get('permissionPackages')
                });
                return;
            })
        ];
        return;
    }

    componentDidMount() {
        // Fetch all permission packages from the server.
        PermissionPackageActions.get(Immutable.fromJS({active: PermissionPackageConstants.FILTERS.ACTIVE_OPTIONS.ACTIVE.id}), 0, 999999);
        return;
    }

    /**
     * First if compares props, second if compares state.
     * @param  {Object} nextProps
     * @param  {Object} nextState
     * @return {bool} true if component should update
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (!CompareImmutable(nextProps.user, this.props.user, presetsUserProps)) {
            return true;
        }

        if (nextState.permissionPackages !== this.state.permissionPackages ||
            nextState.selectedPermissionPackage !== this.state.selectedPermissionPackage) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    }

    handleSelectPermissionPackage(permissionPackage) {
        // This is just for the UI. It's not supposed to be in sync with the API
        // so we don't read it from the store.
        this.setState({
            selectedPermissionPackage: permissionPackage
        });

        if (permissionPackage) {
            // Trigger the select action with the desired immutable object.
            PermissionPackageActions.select(this.state.permissionPackages.find((elem) => {
                return elem.get('id') === permissionPackage.id;
            }));
        }
        return;
    }

    toggleCopyPermissionsModal() {
        this.setState((prevState)=>({
            showCopyPermissionsModal: !prevState.showCopyPermissionsModal
        }));
    }

    render() {
        return (
            <div>
                {this.props.canCopyPermissions && this.props.entity !== 'applicant' && (
                    <Row>
                        <Col md={12}>
                            <Button
                                bsSize="md"
                                className="btn btn-primary-outline Mr(3px) Mb(3px)"
                                onClick={this.toggleCopyPermissionsModal}
                            >
                                <i className="fas fa-copy"/>&nbsp;{this.context.intl.messages['accounts.edit.copy-permissions']}
                            </Button>
                            <span>&nbsp;{this.context.intl.messages['accounts.edit.copy-permissions.button-label']}</span>
                        </Col>
                    </Row>
                )}
                <FormSection title={this.context.intl.messages['accounts.create.permissions.presets']} iconClass="fas fa-list-ul">
                    <FormRow>
                        <FormGroup md={4}>
                            <ControlLabel>{this.context.intl.messages['accounts.create.permissions.permissions-package']}</ControlLabel>
                            <Select
                                getOptionLabel={GetAttr('name')}
                                getOptionValue={GetAttr('id')}
                                isClearable={true}
                                isDisabled={this.props.disabled}
                                name="permissions-package"
                                onChange={this.handleSelectPermissionPackage}
                                options={this.state.permissionPackages.toJS()}
                                placeholder={this.context.intl.messages['accounts.create.permissions.permissions-package-placeholder']}
                                value={this.state.selectedPermissionPackage}
                            />
                        </FormGroup>
                        <FormItem type="textarea"
                            attr="requestAdditionalSecurity"
                            disabled={this.props.disabled}
                            label={this.context.intl.messages['accounts.create.permissions.request-custom-permissions']}
                            model={this.props.user}
                            setter={UserActions.updateUser}
                        />
                    </FormRow>
                </FormSection>
                <CopyPermissionsModal
                    show={this.state.showCopyPermissionsModal}
                    onHide={this.toggleCopyPermissionsModal}
                />
            </div>
        );
    }
}

export default Container.create(Presets);
export {Presets as Presets_BASE};
