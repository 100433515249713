/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, ControlLabel, FormGroup} from 'react-bootstrap';
import Select from 'react-select';

import Expiration from './expiration';
import Presets from './presets';

import {FormRow, FormSection} from '~/src/common/form/form';
import {GetAttr} from '~/src/common/utils/utils';
import Partners from '~/src/partner/partners';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupSelect from '~/src/security/group/group-select';
import RoleSelect from '~/src/security/role/role-select';
import {UserActions, UserConstants} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

/**
 * The default exported module defines the Tab and its contents.
 */

class Permissions extends Component {
    static get propTypes() {
        return {
            canViewStations: PropTypes.bool,
            entity: PropTypes.string,
            permissions: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            canViewStations: false,
            entity: '',
            permissions: {
                edit: false
            }
        };
    }

    static calculateState() {
        return {
            user: UserStore.getState().get('user'),
            userType: UserStore.getState().get('userType'),
            originalUser: UserStore.getState().get('originalUser'),
        };
    }

    static getStores() {
        return [UserStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showInactiveGroups: false,
            showInactiveRoles: false,
            showInactiveStations: false,
        }, this.constructor.calculateState());

        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleShowInactive = this.handleShowInactive.bind(this);
        this.handleSelectBrainicGroup = this.handleSelectBrainicGroup.bind(this);
    }

    handleSelectGroup(groups) {
        groups = groups || [];

        // add hidden event groups
        if (this.state.user.get('eventGroups').size) {
            groups = [...groups, ...this.state.user.get('eventGroups').toJS()];
        }
        // add hidden brainiac groups
        if (this.state.user.get('brainiacGroups').size) {
            groups = [...groups, ...this.state.user.get('brainiacGroups').toJS()];
        }

        if (!groups.length) {
            // Reset the value.
            UserActions.setGroups(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        UserActions.setGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectBrainicGroup(groups) {
        if (!groups.length) {
            // Reset the value.
            UserActions.setBrainiacGroups(Immutable.OrderedSet());

            return;
        }

        // Call the select action but with immutable objects.
        UserActions.setBrainiacGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));

        return;
    }

    handleSelectPartners(partners) {
        if (!partners) {
            UserActions.updatePartners(Immutable.OrderedSet());
            return;
        }
        UserActions.updatePartners(Immutable.fromJS(partners).toSet().sortBy(p => p.get('name')));
        return;
    }

    handleSelectPrimaryPartner(partner) {
        UserActions.updateUser('defaultPartner', Immutable.fromJS(partner));
        return;
    }

    handleSelectRole(roles) {
        if (!roles) {
            // Reset the value.
            UserActions.setRoles(Immutable.OrderedSet());
            return;
        }
        // Call the select action but with immutable objects.
        UserActions.setRoles(Immutable.fromJS(roles).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectStation(stations) {
        if (!stations) {
            UserActions.setStations(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        UserActions.setStations(Immutable.fromJS(stations).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleShowInactive(value, event) {
        this.setState({
            [`showInactive${value}`]: event.target.checked
        });
        return;
    }

    render() {
        let disabled = !this.props.permissions.canEditRolesGroups;
        let stations;

        const brainiacGroups = <FormSection title={this.context.intl.messages['accounts.create.permissions.brainiac-groups']}>
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['accounts.create.permissions.add-group']}</ControlLabel>
                    <GroupSelect
                        disabled={disabled}
                        filterOption={(g) => g.data.isAdmin}
                        name="brainiacGroups"
                        onChange={this.handleSelectBrainicGroup}
                        selected={this.state.user.get('brainiacGroups')}
                        type={GroupConstants.USER}
                        subtype="brainiacGroups"
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        const originalEventGroupsList = this.state.originalUser.get('eventGroups');

        const eventGroups = <FormSection title={this.context.intl.messages['accounts.create.permissions.event-groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['accounts.create.permissions.add-group']}</ControlLabel>
                    <Select
                        isDisabled
                        getOptionLabel={GetAttr('name')}
                        getOptionValue={GetAttr('id')}
                        isMulti
                        value={originalEventGroupsList?.toJS()}
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        let userGroups = this.state.user.get('groups').filter(g => g.get('groupCategoryType') !== GroupConstants.STATION.categoryId);
        userGroups = userGroups.filter(g => { // also show new event groups
            const isNewEventGroup = !(originalEventGroupsList?.find(group => group.get('id') === g.get('id'))) && UserStore.isEventGroup(g);
            return (!UserStore.isEventGroup(g) || isNewEventGroup) && !g.get('isAdmin');
        });

        // WBTVD/WBD Groups
        const groups = <FormSection title={this.context.intl.messages['accounts.create.permissions.groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['accounts.create.permissions.add-group']}</ControlLabel>
                    <Checkbox
                        disabled={disabled}
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Groups')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <GroupSelect
                        disabled={disabled}
                        filterOption={(g) => {
                            return ((!originalEventGroupsList?.find(group => group.get('id') === g.data.id) && g.label.toLowerCase().startsWith('event')) ||
                            !g.label.toLowerCase().startsWith('event') && !g.data.isAdmin);
                        }}
                        onChange={this.handleSelectGroup}
                        selected={userGroups}
                        showInactive={this.state.showInactiveGroups}
                        type={GroupConstants.USER}
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        const roles = <FormSection title={this.context.intl.messages['accounts.create.permissions.roles']} iconClass="fas fa-male">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['accounts.create.permissions.add-role']}</ControlLabel>
                    <Checkbox
                        disabled={disabled}
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Roles')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <RoleSelect
                        disabled={disabled}
                        onChange={this.handleSelectRole}
                        selected={this.state.user.get('roles')}
                        showInactive={this.state.showInactiveRoles}
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        if (this.props.canViewStations) {
            stations = <FormSection title={this.context.intl.messages['accounts.create.permissions.stations']} iconClass="fas fa-tv-retro">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['accounts.create.permissions.add-station']}</ControlLabel>
                        <Checkbox
                            disabled={disabled}
                            className="pull-right show-inactive"
                            onChange={this.handleShowInactive.bind(this, 'Stations')}>
                            {this.context.intl.messages['common.show-inactive']}
                        </Checkbox>
                        <GroupSelect
                            disabled={disabled}
                            onChange={this.handleSelectStation}
                            name="station"
                            selected={this.state.user.get('stations')}
                            showInactive={this.state.showInactiveStations}
                            type={GroupConstants.STATION}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>;
        }

        const isPartnersRequired = this.state.userType.get('id') !== UserConstants.USER_TYPES.ANONYMOUS.id;
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Presets
                    canCopyPermissions={this.props.permissions.canCopyPermissions}
                    entity={this.props.entity}
                    user={this.state.user}
                    disabled={!this.props.permissions.canPermissionPackages && disabled}
                />
                <hr/>

                <Partners
                    defaultPartner={this.state.user.get('defaultPartner')}
                    disabled={disabled}
                    onSelectPrimaryPartner={this.handleSelectPrimaryPartner}
                    onSelectPartners={this.handleSelectPartners}
                    partners={this.state.user.get('partners')}
                    required={isPartnersRequired}
                />

                <hr/>

                {groups}

                <hr/>

                {roles}

                <hr/>

                {brainiacGroups}

                <hr/>

                {eventGroups}

                <hr/>

                {stations}

                <hr />
                <Expiration user={this.state.user} disabled={!this.props.permissions.canEdit}/>
            </div>
        );
    }
}

export default Container.create(Permissions);
