/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';

import Table from './account-information-table';
import {FormSection, FormRow} from '../../../common/form/form';
import {Intersperse} from '../../../common/utils/utils';
import {PartnerActions} from '../../../partner/partner-actions';
import PartnerStore from '../../../partner/partner-store';
import {UserConstants, UserActions} from '../../../user/user-actions';
import UserStore from '../../../user/user-store';

const getUserTypeData = (user, userTypeId, context) => {
    let result = {};

    switch (userTypeId) {
    case UserConstants.USER_TYPES.SECONDARY.id:
        result = {
            color: 'label-default',
            displayName: context.intl.messages[UserConstants.USER_TYPES.SECONDARY.displayName],
            token: user.get('token') || context.intl.messages['common.na'],
            userId: user.get('id') || user.get('userId')
        };
        break;
    case UserConstants.USER_TYPES.ANONYMOUS.id:
        result = {
            color: 'bg-black',
            displayName: context.intl.messages[UserConstants.USER_TYPES.ANONYMOUS.displayName],
            token: user.get('token') || context.intl.messages['common.na'],
            userId: user.get('id') || user.get('userId')
        };
        break;
    default:
        result = {
            color: 'bg-wb-blue',
            displayName: context.intl.messages[UserConstants.USER_TYPES.PRIMARY.displayName],
            token: context.intl.messages['common.na'],
            userId: user.get('id') || user.get('userId')
        };
    }

    result.guilds = user.get('guilds', Immutable.OrderedSet()).toJS();
    result.partners = user.get('partners', Immutable.OrderedSet()).toJS();

    return result;
};

class AccountInformation extends Component {

    static get propTypes() {
        return {
            user: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            relatedAccounts: UserStore.getState().get('relatedAccounts'),
            partners: PartnerStore.getState().get('partners')
        };
    }

    static getStores() {
        return [UserStore, PartnerStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            sortFieldName: 'ID',
            sortDirection: 'desc'
        }, this.constructor.calculateState());

        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        PartnerActions.get(0, 99999);

        UserActions.getRelatedAccounts(this.props.user.get('id'));

        if (UserStore.getUserType(this.props.user).get('id') !== UserConstants.USER_TYPES.PRIMARY) {
            // Only secondary/anonymous accounts should have guilds
            UserActions.getGuilds(this.props.user.get('id'));
        }
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState(() => ({
            sortFieldName,
            sortDirection
        }));
        return;
    }

    render() {
        const userType = UserStore.getUserType(this.props.user);

        // FIXME: api related-accounts does not return ANONYMOUS account type (only PRIMARY, SECONDARY)
        let relatedAccounts = this.state.relatedAccounts.map((account) => {

            let accountType = account.get('type');
            if (account.get('userId') === this.props.user.get('id')) {
                accountType = userType.get('id');
            }

            return getUserTypeData(account, accountType, this.context);
        }).toJS();

        const currentUser = Immutable.fromJS(relatedAccounts.find(u => u.userId === this.props.user.get('id')) || {});

        // Do not show current account account in related table
        relatedAccounts = relatedAccounts.filter(u => u.userId !== this.props.user.get('id'));

        let informationTable;
        if (userType.get('id') !== UserConstants.USER_TYPES.ANONYMOUS.id) {
            informationTable = (
                <div>
                    <hr/>
                    <FormRow>
                        <FormGroup>
                            <h4><i className="fas fa-user-friends"></i>&nbsp;{this.context.intl.messages['accounts.create.summary.information.related']}</h4>
                            <Table
                                onSortChange={this.handleSortChange}
                                sortDirection={this.state.sortDirection}
                                sortFieldName={this.state.sortFieldName}
                                users={Immutable.fromJS(relatedAccounts)}
                            />
                        </FormGroup>
                    </FormRow>
                </div>
            );
        }

        let webPartners = this.context.intl.messages['common.na'];
        if (currentUser.get('partners', []).size) {
            webPartners = currentUser.get('partners').map((p) => {
                return <Link key={`partner-link-${Math.ceil(Math.random() * 100000)}`} to={`/setup/authorized-partners/${p.get('id')}`}>{p.get('name')}</Link>;
            });
            webPartners = Intersperse(webPartners.toJS());
        }

        let guilds = this.context.intl.messages['common.na'];
        if (currentUser.get('guilds', []).size) {
            guilds = currentUser.get('guilds').map((g) => {
                return <Link key={`guild-link-${Math.ceil(Math.random() * 100000)}`} to={`/guild/${g.get('id')}`}>{g.get('name')}</Link>;
            });
            guilds = Intersperse(guilds.toJS());
        }

        return (
            <FormSection title={this.context.intl.messages['accounts.create.summary.information']} iconClass="fa-regular fa-file-user fa-fw">
                <FormRow>
                    <FormGroup className="text-center">
                        <h3><i className="fas fa-user-cog"></i>&nbsp;{this.context.intl.messages['accounts.create.summary.information.type']}</h3>
                        <p><span className={`label ${currentUser.get('color')}`}>{currentUser.get('displayName')}</span></p>
                    </FormGroup>
                    <FormGroup className="text-center">
                        <h3><i className="fas fa-coin"></i>&nbsp;{this.context.intl.messages['accounts.create.summary.information.token']}</h3>
                        <p>{currentUser.get('token')}</p>
                    </FormGroup>
                    <FormGroup className="text-center">
                        <h3><i className="fas fa-handshake-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.summary.information.web-partners']}</h3>
                        <p>{webPartners}</p>
                    </FormGroup>
                    <FormGroup className="text-center">
                        <h3><i className="fas fa-shield-alt"></i>&nbsp;{this.context.intl.messages['accounts.create.summary.information.guilds']}</h3>
                        <p>{guilds}</p>
                    </FormGroup>
                </FormRow>
                {informationTable}
            </FormSection>
        );
    }
}

export default Container.create(AccountInformation);
export {
    getUserTypeData
};
