/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';
import ReactTable from 'react-table';

import DocumentTitle from '../../../common/document-title';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import Pagination from '../../../common/table/pagination';
import Preloader from '../../../preloader/';
import {ReportActions} from '../report-actions';
import {ReportStore} from '../report-store';
import 'react-table/react-table.css';
import './analytics.less';

import {GetNoDataProps} from '~/src/common/react-table/react-table';
import {GetAttr} from '~/src/common/utils/utils';

class Table extends Component {
    static get propTypes() {
        return {
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            location: PropTypes.object.isRequired,
            onPageChange: PropTypes.func
        };
    }

    static get defaultProps() {
        return {
            onPageChange: /*istanbul ignore next*/() => void 0
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        /*istanbul ignore next*/
        let columns = [{
            Header: <strong>{this.context.intl.messages['analytics.reports.table.columns.file']}</strong>,
            Cell: c => <div>{c.original.get('filename')}</div>,
        }, {
            Header: <strong>{this.context.intl.messages['analytics.reports.table.columns.created-date']}</strong>,
            Cell: c => {
                let createdDateFormatted = '-';
                if (c.original.get('createdDate')) {
                    createdDateFormatted = Moment(c.original.get('createdDate')).format(this.context.intl.messages['date-format']);
                }
                return <div>{createdDateFormatted}</div>;
            },
            width: 280,
        }, {
            Header: <i className="fas fa-download"></i>,
            Cell: c => {
                return (
                    <a href={c.original.get('signedUrl')}>
                        <i className="fas fa-download"></i>
                    </a>
                );
            },
            width: 35,
        }];

        const offset = this.props.location.query.offset || 0;
        const size = this.props.location.query.size || 20;

        const reportItems = this.props.items.sort(/*istanbul ignore next*/(a, b) =>
            Moment(b.get('createdDate')) - Moment(a.get('createdDate'))
        ).slice(offset, offset+size);

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive analytics-reports-table"
                    columns={columns}
                    data={reportItems}
                    getNoDataProps= {GetNoDataProps(reportItems.size)}
                    loading={false}
                    resizable={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                    sortable={false}
                />
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={Math.ceil(offset / size)}
                            onChange={this.props.onPageChange}
                            totalPages={Math.ceil(this.props.items.size / size)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class Browse extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            reports: ReportStore.getState().get('reports'),
            reportTypes: ReportStore.getState().get('reportTypes'),
            showPreloader: ReportStore.getState().get('showPreloader'),
        };
    }

    static getStores() {
        return [ReportStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSelectReportType = this.handleSelectReportType.bind(this);
    }

    componentDidMount() {
        ReportActions.findReportTypes();
        if (this.props.location.query['report-type']) {
            ReportActions.findReports(this.props.location.query['report-type']);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            ReportActions.findReports(this.props.location.query['report-type']);
        }
    }

    componentWillUnmount() {
        ReportActions.clear();
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSelectReportType(reportType) {
        this.setRouteState('report-type', reportType.id)
            .clearRouteState('offset')
            .apply();
    }

    render() {
        const options = this.state.reportTypes.toJS().map(/*istanbul ignore next*/reportType => {
            return {
                id: reportType,
                name: this.context.intl.messages[`analytics.reports.types.${reportType}`] || reportType
            };
        });

        const reportSelected = this.props.location.query['report-type'] || '';
        const reportSelectedValue = options.find(/*istanbul ignore next*/option => option.id === reportSelected);

        return (
            <DocumentTitle
                message="document-titles.analytics.scheduled-reports"
            >
                <Preloader show={this.state.showPreloader} fixed loadingDots>
                    <section className="content-header">
                        <h1>
                            <i className="fas fa-file-chart-line"></i>&nbsp;{this.context.intl.messages['analytics.reports.title']}
                        </h1>
                    </section>
                    <section className="content">
                        <div className="container-fluid no-x-padding">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="box-body padding-x-20 padding-y-20">
                                            <FormGroup className="col-md-3">
                                                <ReactSelect
                                                    getOptionLabel={GetAttr('name')}
                                                    getOptionValue={GetAttr('id')}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    name="reportType"
                                                    onChange={this.handleSelectReportType}
                                                    options={options}
                                                    placeholder={this.context.intl.messages['analytics.reports.select-placeholder']}
                                                    value={reportSelectedValue}
                                                />
                                            </FormGroup>
                                            <div className="col-md-12">
                                                <Table
                                                    items={this.state.reports}
                                                    location={this.props.location}
                                                    onPageChange={this.handleLoadPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Preloader>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
export {Browse, Table};
