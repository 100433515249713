/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Checkbox, Col, Row, ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {MassUpdateActions, MassUpdateConstants} from './mass-update-actions';
import MassUpdateStore from './mass-update-store';
import {FormRow, FormSection} from '../../common/form/form';
import {GetAttr} from '../../common/utils/utils';
import {WithValidations} from '../../common/validations/validations';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Partners from '../../partner/partners';
import {RouterActions} from '../../router/router-actions';
import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';
import {GroupConstants} from '../../security/group/group-actions';
import GroupSelect from '../../security/group/group-select';
import RoleSelect from '../../security/role/role-select';

import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';

class Confirmation extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            brainiacGroups: MassUpdateStore.getState().get('brainiacGroups'),
            bulkUpdateGroup: MassUpdateStore.getState().get('bulkUpdateGroup'),
            bulkUpdateRole: MassUpdateStore.getState().get('bulkUpdateRole'),
            clientRepGroups: ClientRepGroupStore.getState().get('clientRepGroups'),
            defaultPartner: MassUpdateStore.getState().get('defaultPartner'),
            mode: MassUpdateStore.getState().get('mode'),
            partners: MassUpdateStore.getState().get('partners'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            roles: MassUpdateStore.getState().get('roles'),
            selectedClientRepGroupIds: MassUpdateStore.getState().get('selectedClientRepGroupIds'),
            showInactiveGroups: false,
            showInactiveRoles: false,
            stations: MassUpdateStore.getState().get('stations'),
            users: MassUpdateStore.getState().get('users'),
            wbdGroups: MassUpdateStore.getState().get('wbdGroups')
        };
    }

    static getStores() {
        return [MassUpdateStore, ClientRepGroupStore, PreloaderStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            excludeStations: false,
            showInactiveGroups: false,
            showInactiveRoles: false,
            showInactiveStations: false,
        }, this.constructor.calculateState());

        this.handleBack = this.handleBack.bind(this);
        this.handleClientRepGroupChange = this.handleClientRepGroupChange.bind(this);
        this.handleExcludeStations = this.handleExcludeStations.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectBrainicGroup = this.handleSelectBrainicGroup.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleSelectRole = this.handleSelectRole.bind(this);
        this.handleSelectStation = this.handleSelectStation.bind(this);
        this.handleShowInactive = this.handleShowInactive.bind(this);
        this.handleSelectPrimaryPartner = this.handleSelectPrimaryPartner.bind(this);
        this.handleSelectPartners = this.handleSelectPartners.bind(this);
    }

    componentDidMount() {
        MassUpdateActions.getConfirmationData(this.props.params.id);
        ClientRepGroupActions.get();
        return;
    }

    handleBack(mode, massModeStep) {
        if (massModeStep) {
            RouterActions.redirect(`/accounts/mass-${mode}/${this.props.params.id}?mode=${massModeStep}`);
        } else {
            RouterActions.redirect(`/accounts/mass-${mode}/${this.props.params.id}`);
        }
    }

    handleClientRepGroupChange(value) {
        let valueIds = null;
        if (value) {
            valueIds = value.map(c => c.id);
        }

        MassUpdateActions.setClientRepGroups(valueIds);
        return;
    }

    handleExcludeStations(event) {
        this.setState({
            excludeStations: event.target.checked
        });
        return;
    }

    handleSave(action) {
        MassUpdateActions.save(
            action,
            this.props.params.id,
            this.state.wbdGroups,
            this.state.brainiacGroups,
            this.state.stations,
            this.state.roles,
            this.state.defaultPartner,
            this.state.partners,
            this.state.selectedClientRepGroupIds,
            this.state.excludeStations,
            null
        );
    }

    handleSelectBrainicGroup(groups) {
        MassUpdateActions.bulkUpdate('bulkUpdateGroup', false);

        if (!groups) {
            MassUpdateActions.setBrainiacGroups(Immutable.OrderedSet());
            return;
        }
        MassUpdateActions.setBrainiacGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectGroup(groups) {
        MassUpdateActions.bulkUpdate('bulkUpdateGroup', false);

        if (!groups) {
            MassUpdateActions.setGroups(Immutable.OrderedSet());
            return;
        }
        MassUpdateActions.setGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectRole(roles) {
        MassUpdateActions.bulkUpdate('bulkUpdateRole', false);

        if (!roles) {
            MassUpdateActions.setRoles(Immutable.OrderedSet());
            return;
        }
        MassUpdateActions.setRoles(Immutable.fromJS(roles).toSet().sortBy(r => r.get('name')));
        return;
    }

    handleSelectStation(groups) {
        MassUpdateActions.bulkUpdate('bulkUpdateGroup', false);

        if (!groups) {
            MassUpdateActions.setStations(Immutable.OrderedSet());
            return;
        }
        MassUpdateActions.setStations(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleShowInactive(value, event) {
        this.setState({
            [`showInactive${value}`]: event.target.checked
        });
        return;
    }

    handleSelectPrimaryPartner(defaultPartner) {
        if (!defaultPartner) {
            MassUpdateActions.setDefaultPartner({});
            return;
        }
        MassUpdateActions.setDefaultPartner(defaultPartner);
    }

    handleSelectPartners(partners) {
        if (!partners) {
            MassUpdateActions.setPartners(Immutable.OrderedSet());
            return;
        }
        MassUpdateActions.setPartners(Immutable.fromJS(partners).toSet().sortBy(r => r.get('name')));
    }

    render() {
        let actionsMenu;
        let titleMessage;
        let stepMessage;
        let massMode = 'update';
        let store = MassUpdateStore;

        //to use in steps texts
        let massModeStep = massMode;
        const queryMode = this.props.location.query.mode;
        if (queryMode) {
            massModeStep = queryMode;
        }

        let totalUsers = '-';
        if (this.state.users) {
            totalUsers = this.state.users.size;
        }
        const validations = store.getValidations();
        let partnerSection;
        let selectedPartners = this.state.partners;
        let defaultPartner = this.state.defaultPartner;

        titleMessage = this.context.intl.messages['accounts.mass-update.title'];
        stepMessage = this.context.intl.messages['accounts.mass-update.accounts-length'];

        //Client Rep Groups
        const clientRepGroupSection = <FormSection title={this.context.intl.messages['accounts.mass-update.client-rep-group.title']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages[`accounts.mass-update.client-rep-group.${this.state.mode}.text`]}</ControlLabel>
                    <ReactSelect
                        getOptionLabel={GetAttr('name')}
                        getOptionValue={GetAttr('id')}
                        isClearable={true}
                        isMulti={true}
                        disabled={false}
                        onChange={this.handleClientRepGroupChange}
                        options={this.state.clientRepGroups.toJS()}
                        selected={this.state.selectedClientRepGroupIds}
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        //WBTVD/WBD Groups
        let selectedWbdGroup = Immutable.List();
        if (!this.state.bulkUpdateGroup) {
            selectedWbdGroup = this.state.wbdGroups;
        }
        const wbdGroupSection = <FormSection title={this.context.intl.messages['accounts.mass-update.wbd-groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages[`accounts.mass-update.groups.${this.state.mode}.text`]}</ControlLabel>
                    <Checkbox
                        disabled={false}
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Groups')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <GroupSelect
                        disabled={false}
                        filterOption={(g) => !g.data.isAdmin}
                        onChange={this.handleSelectGroup}
                        selected={selectedWbdGroup}
                        showInactive={this.state.showInactiveGroups}
                        type={GroupConstants.USER}
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        //Roles
        let selectedRoles = Immutable.List();
        if (!this.state.bulkUpdateRole) {
            selectedRoles = this.state.roles;
        }
        const roleSection = (
            <FormSection title={this.context.intl.messages['accounts.mass-update.roles.title']} iconClass="fas fa-male">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages[`accounts.mass-update.roles.${this.state.mode}.text`]}</ControlLabel>
                        <Checkbox
                            className="pull-right show-inactive"
                            onChange={this.handleShowInactive.bind(this, 'Roles')}>
                            {this.context.intl.messages['common.show-inactive']}
                        </Checkbox>
                        <RoleSelect
                            disabled={false}
                            onChange={this.handleSelectRole}
                            selected={selectedRoles}
                            showInactive={this.state.showInactiveRoles}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>
        );

        // Brainiac User Groups
        let selectedBrainiacGroup = Immutable.List();
        if (!this.state.bulkUpdateGroup) {
            selectedBrainiacGroup = this.state.brainiacGroups;
        }
        const brainiacUserGroupSection = <FormSection title={this.context.intl.messages['accounts.mass-update.brainiac-groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages[`accounts.mass-update.groups.${this.state.mode}.text`]}</ControlLabel>
                    <GroupSelect
                        disabled={false}
                        filterOption={(g) => g.data.isAdmin}
                        name="brainiacGroups"
                        onChange={this.handleSelectBrainicGroup}
                        selected={selectedBrainiacGroup}
                        type={GroupConstants.USER}
                        subtype="brainiacGroups"
                    />
                </FormGroup>
            </FormRow>
        </FormSection>;

        //Stations
        let selectedStations = Immutable.List();
        if (!this.state.bulkUpdateGroup) {
            selectedStations = this.state.stations;
        }
        let stationSection;

        switch (this.state.mode) {
        case 'update':
            actionsMenu = (
                <ActionsMenu onCancel={this.handleCancel}>
                    <Button
                        bsSize="large"
                        className="btn btn-primary-outline Mr(3px) Mb(3px)"
                        disabled={validations.length > 0}
                        onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.UPDATE)}
                    >
                        <i className="fas fa-pencil-alt"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.update-and-overwrite']}
                    </Button>
                    <Button
                        bsSize="large"
                        className="btn btn-navy-outline Mr(3px) Mb(3px)"
                        disabled={validations.length > 0}
                        onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.ADD, massMode)}
                    >
                        <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.add-to-existing']}
                    </Button>
                    <Button
                        bsSize="large"
                        className="btn btn-default-outline Mr(3px) Mb(3px)"
                        onClick={this.handleBack.bind(this, massMode, massModeStep)}
                    >
                        <i className="fas fa-chevron-left"/>&nbsp;{this.context.intl.messages['accounts.mass-common.button.back']}
                    </Button>
                </ActionsMenu>
            );

            partnerSection = (
                <div>
                    <hr/>
                    <Partners
                        defaultPartner={defaultPartner}
                        disabled={false}
                        onSelectPrimaryPartner={this.handleSelectPrimaryPartner}
                        onSelectPartners={this.handleSelectPartners}
                        partners={selectedPartners}
                        required={false}
                    />
                </div>
            );

            stationSection = <FormSection title={this.context.intl.messages['accounts.mass-update.stations']} iconClass="fas fa-tv-retro">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages[`accounts.mass-update.stations.${this.state.mode}.text`]}<span className={ClassNames('exclude-station-text', {'hide': !this.state.excludeStations})}><small>{this.context.intl.messages['accounts.mass-update.exclude-stations.description']}</small></span></ControlLabel>
                        <Checkbox
                            disabled={false}
                            className="pull-right show-inactive"
                            onChange={this.handleExcludeStations.bind(this)}>
                            {this.context.intl.messages['accounts.mass-update.exclude-stations']}
                        </Checkbox>
                        <Checkbox
                            disabled={false}
                            className="pull-right show-inactive padding-x-20"
                            onChange={this.handleShowInactive.bind(this, 'Stations')}>
                            {this.context.intl.messages['common.show-inactive']}
                        </Checkbox>
                        <GroupSelect
                            disabled={this.state.excludeStations}
                            onChange={this.handleSelectStation}
                            name="station"
                            selected={selectedStations}
                            showInactive={this.state.showInactiveStations}
                            type={GroupConstants.STATION}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>;
            break;

        case 'remove':
            actionsMenu = <ActionsMenu onCancel={this.handleCancel}>
                <Button
                    bsSize="large"
                    className="btn btn-danger-outline Mr(3px) Mb(3px)"
                    disabled={validations.length > 0}
                    onClick={this.handleSave.bind(this, MassUpdateConstants.MASS_UPDATE.OPERATION_TYPES.REMOVE, massMode)}
                >
                    <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['accounts.mass-update.button.remove-selected']}
                </Button>
                <Button
                    bsSize="large"
                    className="btn btn-default-outline Mr(3px) Mb(3px)"
                    onClick={this.handleBack.bind(this, massMode, massModeStep)}
                >
                    <i className="fas fa-chevron-left"/>&nbsp;{this.context.intl.messages['accounts.mass-common.button.back']}
                </Button>
            </ActionsMenu>;

            stationSection = <FormSection title={this.context.intl.messages['accounts.mass-update.stations']} iconClass="fas fa-tv-retro">
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages[`accounts.mass-update.stations.${this.state.mode}.text`]}</ControlLabel>
                        <Checkbox
                            disabled={false}
                            className="pull-right show-inactive padding-x-20"
                            onChange={this.handleShowInactive.bind(this, 'Stations')}>
                            {this.context.intl.messages['common.show-inactive']}
                        </Checkbox>
                        <GroupSelect
                            disabled={this.state.excludeStations}
                            onChange={this.handleSelectStation}
                            name="station"
                            selected={selectedStations}
                            showInactive={this.state.showInactiveStations}
                            type={GroupConstants.STATION}
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>;
            break;
        }

        return (
            <div>
                <StickyHeader>
                    <div className="col-md-6">
                        <h1><i className="fas fa-user"></i>&nbsp;{titleMessage}
                            &nbsp;<small>{totalUsers} {stepMessage}</small>
                        </h1>
                    </div>
                    {actionsMenu}
                </StickyHeader>
                <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                    <section className="content" id="contentContainer">
                        <Row>
                            <Col xs={12}>
                                <div className="box">
                                    <div className="box-body padding-x-20">
                                        <h3>{this.context.intl.messages[`accounts.mass-${massModeStep}.step.update-permissions.title`]}</h3>
                                        <div className="padding-y-20">
                                            {clientRepGroupSection}
                                            {partnerSection}
                                            <hr/>
                                            {wbdGroupSection}
                                            <hr/>
                                            {roleSection}
                                            <hr/>
                                            {brainiacUserGroupSection}
                                            <hr/>
                                            {stationSection}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Preloader>
            </div>
        );
    }
}

export default WithValidations(Container.create(Confirmation));
