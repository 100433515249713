{
  "name": "brainiac-client",
  "description": "Brainiac project client",
  "version": "16.2.0",
  "author": "WBTVD",
  "bugs": {
    "url": "https://wbddigital.atlassian.net/jira/software/c/projects/STUDIO/boards/5/backlog"
  },
  "dependencies": {
    "@accurate-player/accurate-player-abr": "7.1.3",
    "@accurate-player/accurate-player-components-external": "5.0.1",
    "@accurate-player/accurate-player-controls": "7.1.3",
    "@accurate-player/accurate-player-core": "7.1.3",
    "@accurate-player/accurate-player-plugins": "7.1.3",
    "@popperjs/core": "2.11.8",
    "@wbdt-sie/brainiac-web-common": "1.3.1",
    "ag-grid-community": "21.2.2",
    "ag-grid-react": "21.2.2",
    "bluebird": "3.7.2",
    "bootstrap": "3.4.1",
    "bootstrap-fileinput": "5.5.4",
    "buffer": "6.0.3",
    "classnames": "2.5.1",
    "create-react-class": "15.7.0",
    "datatables.net": "1.13.1",
    "datatables.net-bs": "1.13.1",
    "datatables.net-responsive": "2.4.0",
    "datatables.net-responsive-bs": "2.4.0",
    "datatables.net-rowreorder": "1.3.1",
    "datatables.net-rowreorder-bs": "1.3.1",
    "defined": "1.0.1",
    "flux": "4.0.4",
    "he": "1.2.0",
    "immutable": "3.8.2",
    "jquery": "3.7.1",
    "lean-he": "2.1.2",
    "moment": "2.30.1",
    "moment-timezone": "0.5.40",
    "prop-types": "15.8.1",
    "query-string": "7.1.3",
    "querystring-es3": "0.2.1",
    "react": "15.6.2",
    "react-addons-css-transition-group": "15.6.2",
    "react-bootstrap": "0.32.4",
    "react-datepicker": "1.6.0",
    "react-dom": "15.6.2",
    "react-intl": "2.4.0",
    "react-overlays": "0.8.3",
    "react-router": "2.8.1",
    "react-router-dom": "5.2.0",
    "react-select": "2.2.0",
    "react-table": "6.11.5",
    "resize-observer-polyfill": "1.5.1",
    "rxjs": "6.6.3",
    "superagent": "3.8.3",
    "url": "0.11.0",
    "uuid": "9.0.1",
    "vtt.js": "0.13.0"
  },
  "devDependencies": {
    "@datadog/datadog-ci": "2.42.0",
    "@babel/core": "7.16.7",
    "@babel/plugin-proposal-class-properties": "7.13.0",
    "@babel/plugin-proposal-object-rest-spread": "7.8.3",
    "@babel/plugin-transform-object-assign": "7.8.3",
    "@babel/preset-env": "7.16.8",
    "@babel/preset-react": "7.16.7",
    "@babel/preset-typescript": "7.13.0",
    "@parcel/babel-preset-env": "2.10.2",
    "@parcel/core": "2.10.2",
    "@parcel/optimizer-terser": "2.10.2",
    "@parcel/transformer-less": "2.10.2",
    "@types/bluebird": "3.5.38",
    "@types/enzyme": "3.10.12",
    "@types/flux": "3.1.14",
    "@types/jest": "29.5.3",
    "@types/jquery": "3.5.29",
    "@types/prop-types": "15.7.5",
    "@types/react": "15.6.20",
    "@types/react-bootstrap": "0.32.32",
    "@types/react-intl": "2.3.18",
    "@types/react-router": "2.0.57",
    "@types/react-select": "2.0.19",
    "@types/superagent": "3.8.3",
    "@types/uuid": "9.0.8",
    "@typescript-eslint/eslint-plugin": "5.62.0",
    "@typescript-eslint/parser": "5.62.0",
    "babel-eslint": "10.1.0",
    "babel-jest": "29.6.1",
    "babel-plugin-istanbul": "6.0.0",
    "core-js": "3.20.2",
    "deep-diff": "1.0.2",
    "enzyme": "3.11.0",
    "enzyme-adapter-react-15": "1.4.4",
    "enzyme-to-json": "3.6.2",
    "eslint": "8.57.0",
    "events": "3.3.0",
    "jest": "29.6.1",
    "jest-environment-jsdom": "29.6.1",
    "jest-environment-node": "29.6.1",
    "jest-junit": "10.0.0",
    "less": "3.12.2",
    "mockdate": "2.0.5",
    "parcel": "2.10.2",
    "parcel-reporter-static-files-copy": "1.4.0",
    "parcel-resolver-ignore": "2.2.0",
    "process": "0.11.10",
    "punycode": "1.4.1",
    "react-test-renderer": "15.6.2",
    "regenerator-runtime": "0.13.11",
    "string_decoder": "1.3.0",
    "typescript": "5.4.5"
  },
  "pnpm": {
    "overrides": {
      "json5": "2.2.2",
      "node-fetch": "2.6.7",
      "ua-parser-js": "0.7.35"
    }
  },
  "homepage": "https://github.com/wbdt-sie/brainiac-web-cms/tree/develop/apps/cms",
  "keywords": [],
  "license": "UNLICENSED",
  "lint-staged": {
    "*.(js|jsx|ts|tsx)": [
      "eslint --config ../../.eslintrc.strict.json --fix"
    ]
  },
  "packageManager": "pnpm@9.10.0",
  "repository": {
    "type": "git",
    "url": "https://github.com/wbdt-sie/brainiac-web-cms.git"
  },
  "parcelIgnore": [
    "tinymce.min.js"
  ],
  "scripts": {
    "clearcache": "rimraf ~/.local/share/pnpm/store/v3 .parcel-cache/ node_modules/ dist/",
    "dist": "./dist.sh",
    "start": "./scripts/start.sh",
    "start:windows": "node node_modules/parcel/lib/bin.js src/index.html -p 9002 --https --log-level verbose --no-hmr",
    "test": "jest --maxWorkers=50%",
    "test:ci": "./tests/test.sh",
    "test:ci_file": "./tests/test_ci_file.sh",
    "test:fast": "./tests/test_no_coverage.sh",
    "test:watch": "jest --watch"
  },
  "browserslist": [
    "Chrome > 80"
  ],
  "targets": {
    "qa": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    },
    "uat": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    },
    "prod": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    }
  }
}
